<div class="sidebar-wrapper" [ngClass]="myInput? 'yes' :'No'" *ngIf="router.url !=('/login')" (click)="onWrapperClick($event)">

  <div #sidebarElement class=" sidebar">
    <div class="logo">
      <img *ngIf="!toggleImageView" src="../../../assets/images/__Iphone-spinner-1.gif" alt="Loader" />
      <img *ngIf="toggleImageView"
      [ngStyle]="{'display': toggleView ? 'block' : 'none'}"
      (load)="onImageLoad()"
       alt="Logo" src="{{publicData.company_logo}}" />
      <!-- <img *ngIf="toggleView" alt="Logo" src="assets/images/Web-Logo@2x.png" /> -->
      <a style="margin-left: 4px;">
        <span class="left" (click)="updateView('sidebarButton')" *ngIf="navState else openNavIcon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
            viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                fill="#EE7444" fill-rule="nonzero"
                transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
              <path
                d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                fill="#EE7444" fill-rule="nonzero" opacity="0.3"
                transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
            </g>
          </svg>
        </span>
        <ng-template #openNavIcon>
          <span class="right" (click)="updateView('sidebarButton')"><svg xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
              class="kt-svg-icon left-icon">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                  fill="#EE7444" fill-rule="nonzero" />
                <path
                  d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                  fill="#EE7444" fill-rule="nonzero" opacity="0.3"
                  transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
              </g>
            </svg>
          </span>
        </ng-template>
      </a>
    </div>
    <div class="nav-options">
      <ul class="toggleUL" [ngClass]="toggleView === true ? 'toggleUL' : 'collapsedUL liMenusCenter'">
        <li (click)="navigateView('dashboard')" [pTooltip]="toggleView ? '' : pageStrings.lblDashboard" [ngClass]="router.url.includes('/dashboard') ? 'active_sidebar' : ''" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
              <path id="Path_22" data-name="Path 22" 
              d="M4,5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1V7a1,1,0,0,1-1,1H5A1,1,0,0,1,4,7Zm0,8a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1Zm12,0a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v6a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1Z" transform="translate(-3.25 -3.25)" fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </svg>
            
            
          </a>
          <div class="menuLabel">
            {{pageStrings.lblDashboard}}
          </div>
        </li>
        <li (click)="navigateView('clinics')" [pTooltip]="toggleView ? '' : pageStrings.lblClinic" [ngClass]="router.url.includes('/clinics') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'superAdmin')
          )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.034" height="16.993" viewBox="0 0 17.034 16.993">
              <path id="clinic" d="M9.582,10v1.2h-1.2a.4.4,0,1,0,0,.8h1.2v1.2a.377.377,0,0,0,.4.4h0a.377.377,0,0,0,.4-.4V12h1.2a.4.4,0,1,0,0-.8h-1.2V10a.4.4,0,1,0-.8,0Zm8.231-1.119L10.221,2.17a.386.386,0,0,0-.559,0L2.07,8.882a.4.4,0,1,0,.559.559l.959-.8v8.95a.377.377,0,0,0,.4.4H15.974a.377.377,0,0,0,.4-.4V8.643l.959.8a.294.294,0,0,0,.24.08h0a.606.606,0,0,0,.32-.16C18.052,9.282,17.972,8.962,17.812,8.882Zm-2.238,8.311H4.387V7.924L9.981,2.969l5.594,4.955Z" transform="translate(-1.45 -1.5)" fill="#fff" stroke="#fff" stroke-width="1"/>
            </svg>
            
            <div class="menuLabel">
              {{pageStrings.lblClinic}}
            </div>
          </a>
        </li>
        <li (click)="navigateView('skills')" [pTooltip]="toggleView ? '' : pageStrings.lblSkills" [ngClass]="router.url.includes('/skills') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'manager') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.017" height="18.089" viewBox="0 0 14.017 18.089">
              <path id="idea" d="M17.711,6.4a2.949,2.949,0,0,1,2.832-2.419,2.994,2.994,0,0,1,2.9,3.076,3.177,3.177,0,0,1-.714,2.022,2.943,2.943,0,0,0-.669,1.868v.03a.7.7,0,0,1-.292.559v.583a1,1,0,0,1-.2.606v5.664a.7.7,0,0,1-.7.7H17.8a.7.7,0,0,1-.7-.7v-1.4h-.8a2.265,2.265,0,0,1-2.265-2.265v-.8h-.168a.977.977,0,0,1-.691-1.667l.858-.858V10.158A3.765,3.765,0,0,1,17.656,6.4h.055Zm-.066.7a3.061,3.061,0,0,0-2.907,3.058v1.531l-1.064,1.064a.273.273,0,0,0,.193.466h.871v1.5A1.561,1.561,0,0,0,16.3,16.28h1.5v2.1h3.061V13.119a1.025,1.025,0,0,1-.1.005h-.43a1.009,1.009,0,0,1-1.009-1.009v-.583a.7.7,0,0,1-.292-.571v-.018a2.943,2.943,0,0,0-.668-1.867A3.172,3.172,0,0,1,17.645,7.1Zm3.418,4.564h-1.04v.45a.305.305,0,0,0,.305.305h.43a.305.305,0,0,0,.305-.305Zm-1.332-.722A3.647,3.647,0,0,0,18.9,8.628a2.473,2.473,0,0,1-.554-1.575,2.2,2.2,0,1,1,4.39,0,2.476,2.476,0,0,1-.554,1.575,3.647,3.647,0,0,0-.828,2.314c0,.012,0,.018,0,.018H19.731Zm6.308-3.9H24.762a.352.352,0,0,0,0,.7h1.276a.352.352,0,0,0,0-.7ZM16.2,3.815l.9.9a.352.352,0,0,0,.5-.5l-.9-.9a.352.352,0,0,0-.5.5Zm8.224-.5-.9.9a.352.352,0,0,0,.5.5l.9-.9a.352.352,0,0,0-.5-.5Zm-4.2-1.466V3.128a.352.352,0,1,0,.7,0V1.852a.352.352,0,1,0-.7,0Z" transform="translate(-12.623 -1.25)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
            </svg>
            
            <div class="menuLabel" style="margin-left: 4px;">
              {{pageStrings.lblSkills}}
            </div>
          </a>
        </li>
        <li (click)="navigateView('planner')" [pTooltip]="toggleView ? '' : pageStrings.lblPlanner" [ngClass]="router.url.includes('/planner') ? 'active_sidebar' : ''" *ngIf="loggedData.role === 'superAdmin'" class="liMenus">
          <a>
            <img src="../../../assets/icon/plannersIco.svg" alt="planners">
            
            <div class="menuLabel">
              {{pageStrings.lblPlanners}}

            </div>
          </a>
        </li>
        <li (click)="navigateView('provider')" [pTooltip]="toggleView ? '' : pageStrings.lblProvider" [ngClass]="router.url.includes('/provider') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="12.739" height="17.004" viewBox="0 0 12.739 17.004">
              <g id="doctor" transform="translate(-12.289 -5.508)">
                <path id="Path_23" data-name="Path 23" d="M23.151,13.1a2.239,2.239,0,0,0-1.157-.1,1.186,1.186,0,0,1-.365.012h-.007a.9.9,0,0,1-.592-.336h0l0,0a1.681,1.681,0,0,1-.271-.485.239.239,0,0,1,.1-.261,1.834,1.834,0,0,0,.479-.6,2.884,2.884,0,0,0,.324-1.348V8.149a2.184,2.184,0,0,0-.675-1.681,3.393,3.393,0,0,0-2.322-.7,3.392,3.392,0,0,0-2.322.7,2.182,2.182,0,0,0-.675,1.681V9.979a2.886,2.886,0,0,0,.325,1.349,1.827,1.827,0,0,0,.476.6.24.24,0,0,1,.1.262A1.054,1.054,0,0,1,15.325,13a2.234,2.234,0,0,0-1.159.1,2.529,2.529,0,0,0-1.627,2.4v4.094c0,.609.351,1.461,2.022,2.064a12.538,12.538,0,0,0,4.1.608c3.046,0,6.119-.826,6.119-2.672V15.5A2.529,2.529,0,0,0,23.151,13.1Zm-1.037,2.651a.526.526,0,1,1-.526.526A.526.526,0,0,1,22.114,15.752Zm-5.572-4.719a2.252,2.252,0,0,1-.251-1.053V8.148a1.56,1.56,0,0,1,.47-1.216A2.833,2.833,0,0,1,18.633,6.4a.314.314,0,0,0,.052,0,2.81,2.81,0,0,1,1.869.533,1.56,1.56,0,0,1,.472,1.218V9.979a2.247,2.247,0,0,1-.25,1.052,1.219,1.219,0,0,1-.308.4.862.862,0,0,0-.3.98,2.491,2.491,0,0,0,.2.407l-1.717,1.427-1.761-1.336a2.4,2.4,0,0,0,.259-.5.862.862,0,0,0-.3-.982A1.214,1.214,0,0,1,16.542,11.033Zm7.607,8.558c0,1.327-2.829,2.043-5.49,2.043s-5.49-.716-5.49-2.043V15.5a1.9,1.9,0,0,1,1.212-1.8,1.615,1.615,0,0,1,.553-.1,1.634,1.634,0,0,1,.284.025c.013,0,.025,0,.037,0a2.768,2.768,0,0,0-.27,1.2v.014a1.312,1.312,0,0,0-1,1.271v1.041a.314.314,0,1,0,.629,0V16.1a.684.684,0,0,1,1.368,0v1.041a.314.314,0,0,0,.629,0V16.1a1.312,1.312,0,0,0-1-1.27v-.014a2.138,2.138,0,0,1,.4-1.241,1.49,1.49,0,0,0,.442-.214l2.017,1.531a.314.314,0,0,0,.391-.008L20.772,13.3a1.486,1.486,0,0,0,.652.314,2.163,2.163,0,0,1,.374,1.209v.353a1.155,1.155,0,1,0,.629,0v-.352a2.783,2.783,0,0,0-.275-1.206,1.609,1.609,0,0,1,.785.079,1.9,1.9,0,0,1,1.212,1.8v4.094Z" transform="translate(0 0)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                <path id="Path_24" data-name="Path 24" d="M28.882,40.936h-.965v-.965a.314.314,0,0,0-.314-.314H26.038a.314.314,0,0,0-.314.314v.965h-.965a.314.314,0,0,0-.314.314v1.565a.314.314,0,0,0,.314.314h.965v.962a.314.314,0,0,0,.314.314H27.6a.314.314,0,0,0,.314-.314V43.13h.965a.314.314,0,0,0,.314-.314V41.251A.315.315,0,0,0,28.882,40.936ZM28.567,42.5H27.6a.314.314,0,0,0-.314.314v.962h-.936v-.962a.314.314,0,0,0-.314-.314h-.965v-.936h.965a.314.314,0,0,0,.314-.314v-.965h.936v.965a.314.314,0,0,0,.314.314h.965Z" transform="translate(-8.162 -23.237)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
              </g>
            </svg>
            
            <div class="menuLabel" style="margin-left: 4px;">
              {{pageStrings.lblProviders}}

            </div>
          </a>
        </li>
        <li (click)="navigateView('patient')" [pTooltip]="toggleView ? '' : pageStrings.lblPatient" [ngClass]="router.url.includes('/patient') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'manager') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.197" height="16.5" viewBox="0 0 13.197 16.5">
              <path id="_8675101_ic_fluent_patient_regular_icon" data-name="8675101_ic_fluent_patient_regular_icon" d="M15.341,2A1.856,1.856,0,0,1,17.2,3.856V16.644A1.856,1.856,0,0,1,15.341,18.5H5.856A1.856,1.856,0,0,1,4,16.644V3.856A1.856,1.856,0,0,1,5.729,2l.127,0Zm.619,11.547H5.237v3.1a.619.619,0,0,0,.619.619h9.485a.619.619,0,0,0,.619-.619ZM7.094,14.785H14.1a.619.619,0,0,1,.084,1.232l-.084.006H7.094A.619.619,0,0,1,7.01,14.79l.084-.006h0ZM15.341,3.237H5.856l-.084.006a.619.619,0,0,0-.535.613V12.31H7.3v-1.86a1.031,1.031,0,0,1,.926-1.026L8.33,9.42h4.536a1.031,1.031,0,0,1,1.026.926l.005.105v1.86H15.96V3.856A.619.619,0,0,0,15.341,3.237Zm-2.681,7.42H8.536V12.31h4.124ZM10.6,4.471A2.062,2.062,0,1,1,8.536,6.533,2.062,2.062,0,0,1,10.6,4.471Zm0,1.237a.825.825,0,1,0,.825.825A.825.825,0,0,0,10.6,5.708Z" transform="translate(-4 -2)" fill="#FFF"/>
            </svg>
            
            <div class="menuLabel" style="margin-left: 4px;">
              {{pageStrings.lblPatients}}

            </div>
          </a>
        </li>
        <li (click)="navigateView('scheduling')" [pTooltip]="toggleView ? '' : pageStrings.lblScheduling" [ngClass]="router.url.includes('/scheduling') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'provider') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="16" viewBox="0 0 14.4 16">
              <g id="_3669345_event_ic_icon" data-name="3669345_event_ic_icon" transform="translate(-6 -2)">
                <path id="Path_25697" data-name="Path 25697" d="M17.2,10.8h-4v4h4ZM16.4,2V3.6H10V2H8.4V3.6H7.6A1.593,1.593,0,0,0,6.008,5.2L6,16.4A1.6,1.6,0,0,0,7.6,18H18.8a1.6,1.6,0,0,0,1.6-1.6V5.2a1.6,1.6,0,0,0-1.6-1.6H18V2Zm2.4,14.4H7.6V7.6H18.8Z" fill="#FFF"/>
              </g>
            </svg>
            
            <div class="menuLabel" style="margin-left: 3px;">
              {{pageStrings.lblScheduling}}
            </div>
          </a>
        </li>
        <li (click)="navigateView('availability')" [pTooltip]="toggleView ? '' : pageStrings.lblUnAvailability" [ngClass]="router.url.includes('/availability') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'provider') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'manager') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg id="_3018586_availability_calendar_custom_date_schedule_icon" data-name="3018586_availability_calendar_custom_date_schedule_icon" xmlns="http://www.w3.org/2000/svg" width="16.4" height="16.4" viewBox="0 0 16.4 16.4">
              <path id="Path_25495" data-name="Path 25495" d="M6.617,27.258l.507-.507-.92-.92-.507.508a3.247,3.247,0,0,0-.976-.433v-.758H3.087v.758a3.228,3.228,0,0,0-1,.445L1.6,25.863l-.92.92.5.5a3.223,3.223,0,0,0-.42.957H0v1.632H.758a3.234,3.234,0,0,0,.433.978l-.507.507.92.919.507-.507a3.245,3.245,0,0,0,.98.434v.757H4.717V32.2a3.244,3.244,0,0,0,.959-.421L6.2,32.3l.92-.919-.52-.52a3.238,3.238,0,0,0,.446-1h.757V28.241H7.051A3.216,3.216,0,0,0,6.617,27.258ZM3.9,30.39a1.338,1.338,0,1,1,1.338-1.338A1.338,1.338,0,0,1,3.9,30.39Z" transform="translate(0 -16.555)" fill="#FFF"/>
              <path id="Path_25496" data-name="Path 25496" d="M12.983,1.367V0H11.617V1.367H4.783V0H3.417V1.367H0V8.1H1.367V6.8H15.033v8.234H8.271V16.4H16.4V1.367Zm2.05,4.066H1.367v-2.7h2.05V4.1H4.783V2.733h6.833V4.1h1.367V2.733h2.05Z" fill="#FFF"/>
            </svg>
            
            <div class="menuLabel">
              {{pageStrings.lblUnAvailability}}

            </div>
          </a>
        </li>
        <li (click)="navigateView('spots')" [pTooltip]="toggleView ? '' : 'Session Types'" [ngClass]="router.url.includes('/spots') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.816" height="17.167" viewBox="0 0 16.816 17.167">
              <g id="_3448012_managament_manager_organize_planning_productivity_icon" data-name="3448012_managament_manager_organize_planning_productivity_icon" transform="translate(-39.164 -34.504)">
                <path id="Path_25475" data-name="Path 25475" d="M113.557,264.709a2.714,2.714,0,1,0,2.251,1.2.255.255,0,0,0-.423.286,2.2,2.2,0,1,1-1.828-.972.255.255,0,1,0,0-.51Z" transform="translate(-68.738 -221.294)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25476" data-name="Path 25476" d="M49.536,197.38l-.667-.667a.213.213,0,0,1-.043-.232l0-.01a.215.215,0,0,1,.2-.137h.944a.255.255,0,0,0,.255-.255v-2.531a.255.255,0,0,0-.255-.255h-.944a.218.218,0,0,1-.2-.143.215.215,0,0,1,.042-.236l.667-.667a.255.255,0,0,0,0-.361L47.747,190.1a.255.255,0,1,0-.361.361l1.609,1.609-.487.487a.738.738,0,0,0,.518,1.25h.688v2.02h-.688a.721.721,0,0,0-.666.447l0,.01a.723.723,0,0,0,.152.793l.487.487-1.429,1.429-.487-.487a.738.738,0,0,0-1.25.518v.688h-2.02v-.689a.738.738,0,0,0-1.25-.518l-.487.487-1.428-1.429.487-.487a.738.738,0,0,0-.518-1.25h-.688V193.8h.688a.738.738,0,0,0,.518-1.25l-.487-.487,1.428-1.428.487.487a.738.738,0,0,0,1.25-.518v-.689h2.02v.689a.723.723,0,0,0,.452.667c.092.038.184.081.273.126a.255.255,0,1,0,.232-.455c-.1-.052-.205-.1-.31-.143a.215.215,0,0,1-.137-.2v-.944a.255.255,0,0,0-.255-.255h-2.53a.255.255,0,0,0-.255.255v.944a.218.218,0,0,1-.143.2.215.215,0,0,1-.236-.042l-.667-.667a.255.255,0,0,0-.361,0L40.1,191.886a.255.255,0,0,0,0,.361l.667.667a.214.214,0,0,1,.042.234l0,.008a.215.215,0,0,1-.2.137h-.944a.255.255,0,0,0-.255.255v2.531a.255.255,0,0,0,.255.255h.944a.218.218,0,0,1,.2.143.215.215,0,0,1-.042.236l-.667.667a.255.255,0,0,0,0,.361l1.789,1.789a.255.255,0,0,0,.361,0l.667-.667a.214.214,0,0,1,.234-.042l.008,0a.215.215,0,0,1,.137.2v.944a.255.255,0,0,0,.255.255h2.53a.255.255,0,0,0,.255-.255v-.944a.214.214,0,0,1,.134-.195l.009,0a.215.215,0,0,1,.236.042l.667.667a.255.255,0,0,0,.361,0l1.789-1.789A.255.255,0,0,0,49.536,197.38Z" transform="translate(0 -148.829)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25477" data-name="Path 25477" d="M377.926,270.53a1.643,1.643,0,1,0,1.643-1.643A1.645,1.645,0,0,0,377.926,270.53Zm2.775,0a1.133,1.133,0,1,1-1.133-1.133A1.134,1.134,0,0,1,380.7,270.53Z" transform="translate(-325.762 -225.314)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25478" data-name="Path 25478" d="M288.207,371.82a.255.255,0,0,0,.255-.255v-.639a1.923,1.923,0,0,0-3.845,0v1.643H281.51a.255.255,0,1,0,0,.51h6.7a.255.255,0,0,0,0-.51h-3.08v-1.644a1.412,1.412,0,1,1,2.824,0v.639A.255.255,0,0,0,288.207,371.82Z" transform="translate(-232.732 -321.659)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25479" data-name="Path 25479" d="M87.09,56a.255.255,0,0,0,.255-.255v-4.29h1.692a.255.255,0,0,0,0-.51H87.09a.255.255,0,0,0-.255.255v4.546A.255.255,0,0,0,87.09,56Z" transform="translate(-45.635 -15.578)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25480" data-name="Path 25480" d="M368.925,51.452h1.73v6.175a.255.255,0,1,0,.51,0V51.2a.255.255,0,0,0-.255-.255h-1.985a.255.255,0,0,0,0,.51Z" transform="translate(-316.854 -15.578)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25481" data-name="Path 25481" d="M178.859,36.973h5.688a.255.255,0,0,0,.255-.255V35.009a.255.255,0,0,0-.255-.255h-5.688a.255.255,0,0,0-.255.255v1.709A.255.255,0,0,0,178.859,36.973Zm.255-1.709h5.177v1.2h-5.177Z" transform="translate(-133.947)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25482" data-name="Path 25482" d="M176.4,292.964a.255.255,0,0,0-.255.255v1.4a.255.255,0,0,0,.255.255h.931a.255.255,0,1,0,0-.51h-.676v-1.14A.255.255,0,0,0,176.4,292.964Z" transform="translate(-131.584 -248.484)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25483" data-name="Path 25483" d="M162.133,133.939h5.761a.255.255,0,0,0,0-.51h-5.761a.255.255,0,1,0,0,.51Z" transform="translate(-117.851 -94.958)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25484" data-name="Path 25484" d="M285.18,188.1h-1.17a.255.255,0,1,0,0,.51h1.17a.255.255,0,0,0,0-.51Z" transform="translate(-235.138 -147.571)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25485" data-name="Path 25485" d="M350.322,124.991a.255.255,0,0,0,.361,0l.723-.723a.255.255,0,1,0-.361-.361l-.542.542-.215-.215a.255.255,0,0,0-.361.361Z" transform="translate(-298.745 -85.724)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                <path id="Path_25486" data-name="Path 25486" d="M350.322,179.664a.255.255,0,0,0,.361,0l.723-.723a.255.255,0,1,0-.361-.361l-.542.542-.215-.215a.255.255,0,0,0-.361.361Z" transform="translate(-298.745 -138.337)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              </g>
            </svg>
            
            <div class="menuLabel">
              Session Types
            </div>
          </a>
        </li>
        <!-- THIS IS FROM V1 Start-->
       <ng-container  *ngIf="!toggleView">
        <li (click)="navigateView('solver')" [pTooltip]="toggleView ? '' : pageStrings.lblSolver" [ngClass]="router.url.includes('/solver') ? 'active_sidebar' : ''" *ngIf="(
          (loggedData.role === 'manager') ||
          (loggedData.role === 'superAdmin') ||
          (loggedData.role === 'planner')
      )" class="liMenus">
        <a>
          <svg xmlns="http://www.w3.org/2000/svg" width="16.816" height="17.167" viewBox="0 0 16.816 17.167">
            <g id="_3448012_managament_manager_organize_planning_productivity_icon" data-name="3448012_managament_manager_organize_planning_productivity_icon" transform="translate(-39.164 -34.504)">
              <path id="Path_25475" data-name="Path 25475" d="M113.557,264.709a2.714,2.714,0,1,0,2.251,1.2.255.255,0,0,0-.423.286,2.2,2.2,0,1,1-1.828-.972.255.255,0,1,0,0-.51Z" transform="translate(-68.738 -221.294)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25476" data-name="Path 25476" d="M49.536,197.38l-.667-.667a.213.213,0,0,1-.043-.232l0-.01a.215.215,0,0,1,.2-.137h.944a.255.255,0,0,0,.255-.255v-2.531a.255.255,0,0,0-.255-.255h-.944a.218.218,0,0,1-.2-.143.215.215,0,0,1,.042-.236l.667-.667a.255.255,0,0,0,0-.361L47.747,190.1a.255.255,0,1,0-.361.361l1.609,1.609-.487.487a.738.738,0,0,0,.518,1.25h.688v2.02h-.688a.721.721,0,0,0-.666.447l0,.01a.723.723,0,0,0,.152.793l.487.487-1.429,1.429-.487-.487a.738.738,0,0,0-1.25.518v.688h-2.02v-.689a.738.738,0,0,0-1.25-.518l-.487.487-1.428-1.429.487-.487a.738.738,0,0,0-.518-1.25h-.688V193.8h.688a.738.738,0,0,0,.518-1.25l-.487-.487,1.428-1.428.487.487a.738.738,0,0,0,1.25-.518v-.689h2.02v.689a.723.723,0,0,0,.452.667c.092.038.184.081.273.126a.255.255,0,1,0,.232-.455c-.1-.052-.205-.1-.31-.143a.215.215,0,0,1-.137-.2v-.944a.255.255,0,0,0-.255-.255h-2.53a.255.255,0,0,0-.255.255v.944a.218.218,0,0,1-.143.2.215.215,0,0,1-.236-.042l-.667-.667a.255.255,0,0,0-.361,0L40.1,191.886a.255.255,0,0,0,0,.361l.667.667a.214.214,0,0,1,.042.234l0,.008a.215.215,0,0,1-.2.137h-.944a.255.255,0,0,0-.255.255v2.531a.255.255,0,0,0,.255.255h.944a.218.218,0,0,1,.2.143.215.215,0,0,1-.042.236l-.667.667a.255.255,0,0,0,0,.361l1.789,1.789a.255.255,0,0,0,.361,0l.667-.667a.214.214,0,0,1,.234-.042l.008,0a.215.215,0,0,1,.137.2v.944a.255.255,0,0,0,.255.255h2.53a.255.255,0,0,0,.255-.255v-.944a.214.214,0,0,1,.134-.195l.009,0a.215.215,0,0,1,.236.042l.667.667a.255.255,0,0,0,.361,0l1.789-1.789A.255.255,0,0,0,49.536,197.38Z" transform="translate(0 -148.829)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25477" data-name="Path 25477" d="M377.926,270.53a1.643,1.643,0,1,0,1.643-1.643A1.645,1.645,0,0,0,377.926,270.53Zm2.775,0a1.133,1.133,0,1,1-1.133-1.133A1.134,1.134,0,0,1,380.7,270.53Z" transform="translate(-325.762 -225.314)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25478" data-name="Path 25478" d="M288.207,371.82a.255.255,0,0,0,.255-.255v-.639a1.923,1.923,0,0,0-3.845,0v1.643H281.51a.255.255,0,1,0,0,.51h6.7a.255.255,0,0,0,0-.51h-3.08v-1.644a1.412,1.412,0,1,1,2.824,0v.639A.255.255,0,0,0,288.207,371.82Z" transform="translate(-232.732 -321.659)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25479" data-name="Path 25479" d="M87.09,56a.255.255,0,0,0,.255-.255v-4.29h1.692a.255.255,0,0,0,0-.51H87.09a.255.255,0,0,0-.255.255v4.546A.255.255,0,0,0,87.09,56Z" transform="translate(-45.635 -15.578)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25480" data-name="Path 25480" d="M368.925,51.452h1.73v6.175a.255.255,0,1,0,.51,0V51.2a.255.255,0,0,0-.255-.255h-1.985a.255.255,0,0,0,0,.51Z" transform="translate(-316.854 -15.578)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25481" data-name="Path 25481" d="M178.859,36.973h5.688a.255.255,0,0,0,.255-.255V35.009a.255.255,0,0,0-.255-.255h-5.688a.255.255,0,0,0-.255.255v1.709A.255.255,0,0,0,178.859,36.973Zm.255-1.709h5.177v1.2h-5.177Z" transform="translate(-133.947)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25482" data-name="Path 25482" d="M176.4,292.964a.255.255,0,0,0-.255.255v1.4a.255.255,0,0,0,.255.255h.931a.255.255,0,1,0,0-.51h-.676v-1.14A.255.255,0,0,0,176.4,292.964Z" transform="translate(-131.584 -248.484)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25483" data-name="Path 25483" d="M162.133,133.939h5.761a.255.255,0,0,0,0-.51h-5.761a.255.255,0,1,0,0,.51Z" transform="translate(-117.851 -94.958)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25484" data-name="Path 25484" d="M285.18,188.1h-1.17a.255.255,0,1,0,0,.51h1.17a.255.255,0,0,0,0-.51Z" transform="translate(-235.138 -147.571)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25485" data-name="Path 25485" d="M350.322,124.991a.255.255,0,0,0,.361,0l.723-.723a.255.255,0,1,0-.361-.361l-.542.542-.215-.215a.255.255,0,0,0-.361.361Z" transform="translate(-298.745 -85.724)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
              <path id="Path_25486" data-name="Path 25486" d="M350.322,179.664a.255.255,0,0,0,.361,0l.723-.723a.255.255,0,1,0-.361-.361l-.542.542-.215-.215a.255.255,0,0,0-.361.361Z" transform="translate(-298.745 -138.337)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
            </g>
          </svg>
          
          <div class="menuLabel">
            {{pageStrings.lblSolver}}

          </div>
        </a>
      </li>
       </ng-container>
       <!-- THIS IS FROM V1 End-->
        <ng-container *ngIf="toggleView">
          <li [pTooltip]="toggleView ? '' : pageStrings.lblSolver" *ngIf="(
            (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'planner')
        )" class="SolverliMenus solver-accordion-li solverAccPd">

        <p-accordion [activeIndex]="accordionindex" expandIcon="pi pi-fw pi-chevron-right" collapseIcon="pi pi-fw pi-chevron-down">
          <p-accordionTab [selected]="false" styleClass="solver-accordion">
            <p-header>
              <div (click)="navigateView('solver')" class="solver-accordian-header" [ngClass]="router.url.includes('/solver') ? 'active_sidebar' : ''" >
                <svg xmlns="http://www.w3.org/2000/svg" width="16.816" height="17.167" viewBox="0 0 16.816 17.167">
                  <g id="_3448012_managament_manager_organize_planning_productivity_icon" data-name="3448012_managament_manager_organize_planning_productivity_icon" transform="translate(-39.164 -34.504)">
                    <path id="Path_25475" data-name="Path 25475" d="M113.557,264.709a2.714,2.714,0,1,0,2.251,1.2.255.255,0,0,0-.423.286,2.2,2.2,0,1,1-1.828-.972.255.255,0,1,0,0-.51Z" transform="translate(-68.738 -221.294)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25476" data-name="Path 25476" d="M49.536,197.38l-.667-.667a.213.213,0,0,1-.043-.232l0-.01a.215.215,0,0,1,.2-.137h.944a.255.255,0,0,0,.255-.255v-2.531a.255.255,0,0,0-.255-.255h-.944a.218.218,0,0,1-.2-.143.215.215,0,0,1,.042-.236l.667-.667a.255.255,0,0,0,0-.361L47.747,190.1a.255.255,0,1,0-.361.361l1.609,1.609-.487.487a.738.738,0,0,0,.518,1.25h.688v2.02h-.688a.721.721,0,0,0-.666.447l0,.01a.723.723,0,0,0,.152.793l.487.487-1.429,1.429-.487-.487a.738.738,0,0,0-1.25.518v.688h-2.02v-.689a.738.738,0,0,0-1.25-.518l-.487.487-1.428-1.429.487-.487a.738.738,0,0,0-.518-1.25h-.688V193.8h.688a.738.738,0,0,0,.518-1.25l-.487-.487,1.428-1.428.487.487a.738.738,0,0,0,1.25-.518v-.689h2.02v.689a.723.723,0,0,0,.452.667c.092.038.184.081.273.126a.255.255,0,1,0,.232-.455c-.1-.052-.205-.1-.31-.143a.215.215,0,0,1-.137-.2v-.944a.255.255,0,0,0-.255-.255h-2.53a.255.255,0,0,0-.255.255v.944a.218.218,0,0,1-.143.2.215.215,0,0,1-.236-.042l-.667-.667a.255.255,0,0,0-.361,0L40.1,191.886a.255.255,0,0,0,0,.361l.667.667a.214.214,0,0,1,.042.234l0,.008a.215.215,0,0,1-.2.137h-.944a.255.255,0,0,0-.255.255v2.531a.255.255,0,0,0,.255.255h.944a.218.218,0,0,1,.2.143.215.215,0,0,1-.042.236l-.667.667a.255.255,0,0,0,0,.361l1.789,1.789a.255.255,0,0,0,.361,0l.667-.667a.214.214,0,0,1,.234-.042l.008,0a.215.215,0,0,1,.137.2v.944a.255.255,0,0,0,.255.255h2.53a.255.255,0,0,0,.255-.255v-.944a.214.214,0,0,1,.134-.195l.009,0a.215.215,0,0,1,.236.042l.667.667a.255.255,0,0,0,.361,0l1.789-1.789A.255.255,0,0,0,49.536,197.38Z" transform="translate(0 -148.829)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25477" data-name="Path 25477" d="M377.926,270.53a1.643,1.643,0,1,0,1.643-1.643A1.645,1.645,0,0,0,377.926,270.53Zm2.775,0a1.133,1.133,0,1,1-1.133-1.133A1.134,1.134,0,0,1,380.7,270.53Z" transform="translate(-325.762 -225.314)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25478" data-name="Path 25478" d="M288.207,371.82a.255.255,0,0,0,.255-.255v-.639a1.923,1.923,0,0,0-3.845,0v1.643H281.51a.255.255,0,1,0,0,.51h6.7a.255.255,0,0,0,0-.51h-3.08v-1.644a1.412,1.412,0,1,1,2.824,0v.639A.255.255,0,0,0,288.207,371.82Z" transform="translate(-232.732 -321.659)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25479" data-name="Path 25479" d="M87.09,56a.255.255,0,0,0,.255-.255v-4.29h1.692a.255.255,0,0,0,0-.51H87.09a.255.255,0,0,0-.255.255v4.546A.255.255,0,0,0,87.09,56Z" transform="translate(-45.635 -15.578)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25480" data-name="Path 25480" d="M368.925,51.452h1.73v6.175a.255.255,0,1,0,.51,0V51.2a.255.255,0,0,0-.255-.255h-1.985a.255.255,0,0,0,0,.51Z" transform="translate(-316.854 -15.578)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25481" data-name="Path 25481" d="M178.859,36.973h5.688a.255.255,0,0,0,.255-.255V35.009a.255.255,0,0,0-.255-.255h-5.688a.255.255,0,0,0-.255.255v1.709A.255.255,0,0,0,178.859,36.973Zm.255-1.709h5.177v1.2h-5.177Z" transform="translate(-133.947)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25482" data-name="Path 25482" d="M176.4,292.964a.255.255,0,0,0-.255.255v1.4a.255.255,0,0,0,.255.255h.931a.255.255,0,1,0,0-.51h-.676v-1.14A.255.255,0,0,0,176.4,292.964Z" transform="translate(-131.584 -248.484)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25483" data-name="Path 25483" d="M162.133,133.939h5.761a.255.255,0,0,0,0-.51h-5.761a.255.255,0,1,0,0,.51Z" transform="translate(-117.851 -94.958)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25484" data-name="Path 25484" d="M285.18,188.1h-1.17a.255.255,0,1,0,0,.51h1.17a.255.255,0,0,0,0-.51Z" transform="translate(-235.138 -147.571)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25485" data-name="Path 25485" d="M350.322,124.991a.255.255,0,0,0,.361,0l.723-.723a.255.255,0,1,0-.361-.361l-.542.542-.215-.215a.255.255,0,0,0-.361.361Z" transform="translate(-298.745 -85.724)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                    <path id="Path_25486" data-name="Path 25486" d="M350.322,179.664a.255.255,0,0,0,.361,0l.723-.723a.255.255,0,1,0-.361-.361l-.542.542-.215-.215a.255.255,0,0,0-.361.361Z" transform="translate(-298.745 -138.337)" fill="#FFF" stroke="#FFF" stroke-width="0.5"/>
                  </g>
                </svg>
                <label style="margin: 0px;font-size: 16px;vertical-align: sub;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;color: white;cursor: pointer;">
                  Solver
                </label>
              </div>
            </p-header>
              <div class="solver_Type">
                <div (click)="navigateView('individualgroup-solver')" class="row solver-accordian-data-row" [ngClass]="router.url.includes('/solver/individualgroup-solver') ? 'active_sidebar' : 'solver_sidebar'">
                  Individual & Group Solver
                </div>
                <div (click)="navigateView('bcba-solver')" class="row solver-accordian-data-row" [ngClass]="router.url.includes('/solver/bcba-solver') ? 'active_sidebar' : 'solver_sidebar'">
                  BCBA Solver
                </div>
                <div (click)="navigateView('ot-solver')" class="row solver-accordian-data-row" [ngClass]="router.url.includes('/solver/ot-solver') ? 'active_sidebar' : 'solver_sidebar'">
                  OT Solver
                </div>
                <div (click)="navigateView('speech-solver')" class="row solver-accordian-data-row" [ngClass]="router.url.includes('/solver/speech-solver') ? 'active_sidebar' : 'solver_sidebar'">
                  Speech Solver
                </div>
              </div>
          </p-accordionTab>
        </p-accordion>


        </li>
        </ng-container>
        <li (click)="navigateView('contracts')" [pTooltip]="toggleView ? '' : pageStrings.lblContracts" [ngClass]="router.url.includes('/contracts') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="16.625" viewBox="0 0 14.25 16.625">
              <g id="contract" transform="translate(-4 -2)">
                <path id="Path_25494" data-name="Path 25494" d="M85.875,360.656v6.388a1.782,1.782,0,0,1-1.781,1.781H75.781A1.782,1.782,0,0,1,74,367.044V353.981a1.782,1.782,0,0,1,1.781-1.781h8.313a1.782,1.782,0,0,1,1.781,1.781v1.833a1.186,1.186,0,0,1,1.188.3l.84.84a1.187,1.187,0,0,1,0,1.679ZM82.1,359.395l2.588-2.589v-2.824a.594.594,0,0,0-.594-.594H75.781a.594.594,0,0,0-.594.594v13.063a.594.594,0,0,0,.594.594h8.313a.594.594,0,0,0,.594-.594v-5.2l-3.836,3.837a5.342,5.342,0,0,1-2.769,1.468l-.647.124a.594.594,0,0,1-.7-.7s.055-.287.124-.647a5.342,5.342,0,0,1,1.468-2.769l2.649-2.649H76.969a.594.594,0,1,1,0-1.187h4.852A.588.588,0,0,1,82.1,359.395Zm-3.092,4.781a4.157,4.157,0,0,0-.929,1.758,4.148,4.148,0,0,0,1.759-.928Zm.835-.844.84.84,6.381-6.381-.84-.84Zm-2.873-5.194h5.938a.594.594,0,0,0,0-1.187H76.969a.594.594,0,1,0,0,1.188Zm1.188-2.375h3.563a.594.594,0,1,0,0-1.187H78.156a.594.594,0,0,0,0,1.188Z" transform="translate(-70 -350.2)" fill="#FFF"/>
              </g>
            </svg>
            
            <div class="menuLabel" style="margin-left: 3px;">
              {{pageStrings.lblContracts}}

            </div>
          </a>
        </li>


        <!-- new -->
        <li (click)="navigateView('patient-team')" [pTooltip]="toggleView ? '' : 'Patient Team'" [ngClass]="router.url.includes('/patient-team') ? 'active_sidebar' : ''" *ngIf="(false)" class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.197" height="16.5" viewBox="0 0 13.197 16.5">
              <path id="_8675101_ic_fluent_patient_regular_icon" data-name="8675101_ic_fluent_patient_regular_icon" d="M15.341,2A1.856,1.856,0,0,1,17.2,3.856V16.644A1.856,1.856,0,0,1,15.341,18.5H5.856A1.856,1.856,0,0,1,4,16.644V3.856A1.856,1.856,0,0,1,5.729,2l.127,0Zm.619,11.547H5.237v3.1a.619.619,0,0,0,.619.619h9.485a.619.619,0,0,0,.619-.619ZM7.094,14.785H14.1a.619.619,0,0,1,.084,1.232l-.084.006H7.094A.619.619,0,0,1,7.01,14.79l.084-.006h0ZM15.341,3.237H5.856l-.084.006a.619.619,0,0,0-.535.613V12.31H7.3v-1.86a1.031,1.031,0,0,1,.926-1.026L8.33,9.42h4.536a1.031,1.031,0,0,1,1.026.926l.005.105v1.86H15.96V3.856A.619.619,0,0,0,15.341,3.237Zm-2.681,7.42H8.536V12.31h4.124ZM10.6,4.471A2.062,2.062,0,1,1,8.536,6.533,2.062,2.062,0,0,1,10.6,4.471Zm0,1.237a.825.825,0,1,0,.825.825A.825.825,0,0,0,10.6,5.708Z" transform="translate(-4 -2)" fill="#FFF"/>
            </svg>
            
            <div class="menuLabel">
              Patient Team
            </div>
          </a>
        </li>

        <li (click)="navigateView('therapygroup')" [pTooltip]="toggleView ? '' : 'Therapy Group'" [ngClass]="router.url.includes('/therapygroup') ? 'active_sidebar' : ''" *ngIf="(
          (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin') ||
            (loggedData.role === 'planner')
        )" class="liMenus">
          <a>
            <i class="fa fa-users" aria-hidden="true" style="color: #fff;"></i>
            <div class="menuLabel" style="margin-left: 13px;">
              Therapy Group
            </div>
          </a>
        </li>

        <li (click)="navigateView('holidays')" [pTooltip]="toggleView ? '' : 'Holidays'" [ngClass]="router.url.includes('/holidays') ? 'active_sidebar' : ''"  class="liMenus">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.197" height="16.5" viewBox="0 0 13.197 16.5">
              <path id="_8675101_ic_fluent_patient_regular_icon" data-name="8675101_ic_fluent_patient_regular_icon" d="M15.341,2A1.856,1.856,0,0,1,17.2,3.856V16.644A1.856,1.856,0,0,1,15.341,18.5H5.856A1.856,1.856,0,0,1,4,16.644V3.856A1.856,1.856,0,0,1,5.729,2l.127,0Zm.619,11.547H5.237v3.1a.619.619,0,0,0,.619.619h9.485a.619.619,0,0,0,.619-.619ZM7.094,14.785H14.1a.619.619,0,0,1,.084,1.232l-.084.006H7.094A.619.619,0,0,1,7.01,14.79l.084-.006h0ZM15.341,3.237H5.856l-.084.006a.619.619,0,0,0-.535.613V12.31H7.3v-1.86a1.031,1.031,0,0,1,.926-1.026L8.33,9.42h4.536a1.031,1.031,0,0,1,1.026.926l.005.105v1.86H15.96V3.856A.619.619,0,0,0,15.341,3.237Zm-2.681,7.42H8.536V12.31h4.124ZM10.6,4.471A2.062,2.062,0,1,1,8.536,6.533,2.062,2.062,0,0,1,10.6,4.471Zm0,1.237a.825.825,0,1,0,.825.825A.825.825,0,0,0,10.6,5.708Z" transform="translate(-4 -2)" fill="#FFF"/>
            </svg>
            
            <div class="menuLabel" style="margin-left: 3px;">
              Holidays
            </div>
          </a>
        </li>

        <li (click)="navigateView('setting')" [pTooltip]="toggleView ? '' : 'Settings'" [ngClass]="router.url.includes('/setting') ? 'active_sidebar' : ''" *ngIf="(
          (loggedData.role === 'manager') ||
            (loggedData.role === 'superAdmin')
        )"  class="liMenus">
          <a>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="13.197" height="16.5" viewBox="0 0 13.197 16.5">
              <path id="_8675101_ic_fluent_patient_regular_icon" data-name="8675101_ic_fluent_patient_regular_icon" d="M15.341,2A1.856,1.856,0,0,1,17.2,3.856V16.644A1.856,1.856,0,0,1,15.341,18.5H5.856A1.856,1.856,0,0,1,4,16.644V3.856A1.856,1.856,0,0,1,5.729,2l.127,0Zm.619,11.547H5.237v3.1a.619.619,0,0,0,.619.619h9.485a.619.619,0,0,0,.619-.619ZM7.094,14.785H14.1a.619.619,0,0,1,.084,1.232l-.084.006H7.094A.619.619,0,0,1,7.01,14.79l.084-.006h0ZM15.341,3.237H5.856l-.084.006a.619.619,0,0,0-.535.613V12.31H7.3v-1.86a1.031,1.031,0,0,1,.926-1.026L8.33,9.42h4.536a1.031,1.031,0,0,1,1.026.926l.005.105v1.86H15.96V3.856A.619.619,0,0,0,15.341,3.237Zm-2.681,7.42H8.536V12.31h4.124ZM10.6,4.471A2.062,2.062,0,1,1,8.536,6.533,2.062,2.062,0,0,1,10.6,4.471Zm0,1.237a.825.825,0,1,0,.825.825A.825.825,0,0,0,10.6,5.708Z" transform="translate(-4 -2)" fill="#FFF"/>
            </svg> -->
              <i class="fa fa-cogs" aria-hidden="true" style="color: white;"></i>
            
            
            
            <div class="menuLabel" style="margin-left: 13px;">
              Settings
            </div>
          </a>
        </li>


        <li (click)="navigateView('job-config')" [pTooltip]="toggleView ? '' : 'Job Config'" 
          [ngClass]="router.url.includes('/job-config') ? 'active_sidebar' : ''" 
          *ngIf="(loggedData.role === 'superAdmin')"  class="liMenus">
          <a>
            <i class="fa fa-users" aria-hidden="true" style="color: #fff;"></i>
            
            <div class="menuLabel" style="margin-left: 13px;">
              Job Config
            </div>
          </a>
        </li>
        <li (click)="navigateView('trash')" [pTooltip]="toggleView ? '' : 'Trash'" 
          [ngClass]="router.url.includes('/trash') ? 'active_sidebar' : ''" *ngIf="(
            (loggedData.role === 'manager') ||
              (loggedData.role === 'superAdmin') || (loggedData.role === 'planner')
          )"
            class="liMenus">
          <a>
            <i class="fas fa-trash-alt" aria-hidden="true" style="color: #fff;"></i>
            <div class="menuLabel" style="margin-left: 13px;">
              Trash
            </div>
          </a>
        </li>
      </ul>
    </div>




























  </div>
</div>