import { ForgotPasswordComponent } from './authenticate/forgot-password/forgot-password.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './authenticate/login/login.component';
import { InitialComponent } from './initial/initial.component';
import { InitialAuthGuard }  from './initial/initial.guard';
import { RoleGuardService} from "./initial/initial-role-guard";
import { ResetPasswordComponent } from './authenticate/reset-password/reset-password.component';
import { SignupComponent } from './authenticate/signup/signup.component';
import { LoginAuthGuard } from './initial/login-guard';

const routes: Routes = [
  {
    path: '',
    component: InitialComponent,
    canActivate: [InitialAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },

      {
        path: 'clinics',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin']
        } ,
        loadChildren: () => import('./clinics/clinics.module').then(m => m.ClinicModule),
      },
      {
        path: 'user',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner','provider']
        } ,
        loadChildren: () => import('./clinics/clinics.module').then(m => m.ClinicModule),
      },

      {
        path: 'dashboard',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner','provider']
        } ,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'planner',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin']
        } ,
        loadChildren: () => import('./planner/planner.module').then(m => m.PlannerModule)
      },

      {
        path: 'provider',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./provider/provider.module').then(m => m.ProviderModule)
      },


      {
        path: 'skills',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./skills/skills.module').then(m => m.SkillsModule)
      },

      {
        path: 'spots',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./spots/spots.module').then(m => m.SpotsModule)
      },
      {
        path: 'contracts',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule)
      },
      {
        path: 'solver',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./solver/solver.module').then(m => m.SolverModule)
      },

      {
        path: 'patient',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule)
      },

      {
        path: 'patient-team',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./patient-team/patient-team.module').then(m => m.PatientTeamModule)
      },

      {
        path: 'availability',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner','provider']
        } ,
        loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityModule)
      },

      {
        path: 'scheduling',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner','provider']
        } ,
        loadChildren: () => import('./scheduling/scheduling.module').then(m => m.SchedulingModule)
      },

      {
        path: 'therapygroup',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./behaviourtherapygroup/therapygroup.module').then(m=>m.TherapygroupModule)
      },
      {
        path: 'holidays',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner','provider']
        } ,
        loadChildren: () => import('./holidays/holidays.module').then(m=>m.HolidaysModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
      },
      {
        path: 'setting',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager']
        } ,
        loadChildren: () => import('./setting/setting.module').then(m=>m.SettingModule)
      },
      {
        path: 'job-config',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin']
        } ,
        loadChildren: () => import('./job-config/job-config.module').then(m=>m.jobConfigModule)
      },
      {
        path: 'trash',
        canActivate: [RoleGuardService], 
        data: { 
          expectedRoles: ['superAdmin','manager','planner']
        } ,
        loadChildren: () => import('./job-config/trash-component/trash/trash.module').then(m=>m.TrashModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () => import('./authenticate/authenticate.module').then(m => m.AuthenticateModule),
  },
  // {
  //   path: 'signup',
  //   component: SignupComponent,
  //   loadChildren: () => import('./authenticate/authenticate.module').then(m => m.AuthenticateModule)
  // },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
    loadChildren: () => import('./authenticate/authenticate.module').then(m => m.AuthenticateModule)
  },
  {
    path: 'forget-email-password/:user_id/:hash',
    component: ResetPasswordComponent,
    loadChildren: () => import('./authenticate/authenticate.module').then(m => m.AuthenticateModule)
  },
  {
    path: 'reset-password/:user_id',
    component: ResetPasswordComponent,
    loadChildren: () => import('./authenticate/authenticate.module').then(m => m.AuthenticateModule)
  },
  {
    path: '**',
    redirectTo: 'login',
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
