import { DashboardMeetingService } from './../../dashboard/dashboard.service';
import { PopUpService } from './../pop-up/pop-up.service';
import { Utils } from './../../utils';
import { AppExService } from './../../app-ex.service';
import { AuthenticateService } from "./../../authenticate/authenticate.service";
import { AppService } from "./../../app.service";
import { Subscription } from "rxjs";
import { MenuItem } from 'primeng/api';
import { MessageService } from "primeng/api";
import { ClinicService } from 'src/app/clinics/clinics.service';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import {  ChartType } from 'chart.js';
import { Label,SingleDataSet } from 'ng2-charts';
import {
  ViewEncapsulation,
  Component, OnInit, OnDestroy, Input, Renderer2, HostListener, Output, EventEmitter,
} from "@angular/core";
import { ModalState } from "../shared.model";
import { ProfileService } from "../../my-profile/profile.service";
import { Title } from '@angular/platform-browser';
import { getLanguage } from './../../languages';
import { LanguageInterface } from './../../languages/interface.language';
import { config } from 'src/app/config';
import { AvailabilityService } from 'src/app/availability/availability.service';
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss", "./add-head.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() myOutput: EventEmitter<any> = new EventEmitter();
  @Input() myOutputChange: any;
  outputString = "This is child means header";
  openCalendarMsg = false;
  spinnerSub: Subscription;
  notificationCount = 0;
  notificationMsg: Array<any> = [];
  notificationCountshow = 0
  noCount = "";
  openSubPlan = false;
  openAddCustomerPopup = false;
  openSubProduct = false;
  openSubQuotation = false;
  showSidebarElement = false;
  showSearchField = false;
  items: MenuItem[];
  selectedClinic = null;
  openAddressPop = false;
  notificationWrapper = false;
  billingList = [];
  shippingList = [];
  userType: string;
  currentRoute: string;
  countriesData: Array<any> = [];
  states: Array<any> = [];
  tags: Array<any> = [];
  customerList = [];
  AllClinic = [{ label: 'All ', value: 'id' },]
  addressList = [
    { label: 'Billing Address', value: 'billing' },
    { label: 'Shipping Address', value: 'shipping' },
    { label: 'Other', value: 'other' },
  ];
  addressData = { contact: null, address_type: null, street1: null, street2: null, zip: null, city: null, state: null, country: null };
  showNotification = false;
  noteText: string;
  showHelpData = false;
  showAddNotification = false;
  settingSidebar = false;
  accessTypeList = [];
  openNotesSubs: Subscription;
  openMailSubs: Subscription;
  openTextMsgSubs: Subscription;
  openCalenderSubs: Subscription;
  openPlanSubs: Subscription;
  openProductSubs: Subscription;
  openQuotationSubs: Subscription;
  openSecurityRole = false;
  roleName = null;

  openNotes = false;
  openMail = false;
  assignRequestPopup = false;
  assignUrlPopup = false;
  headerData = false;
  assignFolderPopup = false;
  assignSharePopup = false;
  openTextMsg = false;
  showAddActions = false;
  openSetting = false;
  showTaskPoup = false;
  contactData: Array<any> = [];
  allAssociatedContacts: Array<any> = [];
  countData: any = {};
  countExist = false;
  countSubs: Subscription;
  listClinic: any[] = []
  listClinic2: any[] = []
  listLanguage = [
    { label: 'EN', value: 'EN' },
    { label: 'MEX', value: 'MEX' },
  ];
  taskMode: string;
  showTaskPoupSubs: Subscription;
  showTaskPoupDataSubs: Subscription;
  tab: any = "tab1";
  tab1: any;
  tab2: any;
  tab3: any;
  showScorePop = false;
  showAllNotifBtn = true;
  // listHospitals = [
  //   {label:'Post Office Newyork (70 Employees)', value:'Post Office Newyork (70 Employees)'},
  //   {label:'Post Office Newyork (70 Employees)', value:'Post Office Newyork (70 Employees)'},
  //   {label:'Post Office Newyork (70 Employees)', value:'Post Office Newyork (70 Employees)'},
  //   {label:'Post Office Newyork (70 Employees)', value:'Post Office Newyork (70 Employees)'},
  //   {label:'Post Office Newyork (70 Employees)', value:'Post Office Newyork (70 Employees)'},
  // ]
  pageData = { name: "", count: "", subName: '' };
  userName = "";
  userInitial = "";
  sendTextMessageDescription: boolean;
  openRolesSubs: Subscription;
  modal: ModalState;
  headerSub: Subscription;
  headerClinicListUpdateSub: Subscription;
  updateHeaderNotificationSub: Subscription;
  getData: Subscription;
  getRouteInfo: Subscription;
  openSettingSubs: Subscription;
  showList = false;
  alertData: any = [];
  @Input() rolesList;
  fromEmployeeTab: boolean;
  unread: any;
  loggedInUserId: any;
  imageUrl = "";
  blogListTotal: any;
  parsedDocData = null;
  folderName = '';
  accessType = 'PERSONAL';
  userData = null;
  langSubs: Subscription;
  pageStrings: LanguageInterface;
  selectedLanguage = "EN";
  url = null;
  Datacount: any
  Providercount: number
  PatientCount: number
  SessionCount: number
  Femalecount: number = 0
  MaleCount: number = 0
  response: boolean
  genderCount: any
  totalCountNotifications: any;

  stopInterval: any
  stopRefreshTokenInterval: any
  notification: Subscription;
  imgBaseURL = config.baseUrl;
  isMobileView: Boolean = false;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  greetMessage: any;
  userMutiRoleTypeList: any = [];
  userMutiRoleTypeModel: any;
  isAllClinic: Boolean = false;
  clinicDropDown = [];
  transformedData: any;
  loggedUser: any;

  constructor(
    public authService: AuthenticateService,
    public profileService: ProfileService,
    public messageService: MessageService,
    public utils: Utils,
    public appService: AppService,
    public route: ActivatedRoute,
    public router: Router,
    public renderer: Renderer2,
    public popUpService: PopUpService,
    readonly appExService: AppExService,
    public title: Title,
    public dashboardService: DashboardMeetingService,
    public clinicService: ClinicService,
    private availabilityServices: AvailabilityService
  ) {
    this.modal = new ModalState();
    this.isMobileView = window.innerWidth <= 600;

  }
  documentOptions = [
    { label: 'Request Document' },
    { label: 'Upload Document' },
  ];
  public pieChartLabels: Label[] = ['Male', 'Female'];
  public pieChartData: SingleDataSet = [0, 0]
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  onClinicChange() {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.profileService.setSelectedClinic({ userID: remD.id, clinicID: this.selectedClinic }).subscribe((res) => {
      if (res) {
        (this.selectedClinic > 0) &&
          localStorage.setItem("assignedClinic", this.selectedClinic);

      }
      if (this.selectedClinic === -1) {
        this.isAllClinic = true;
      } else {
        this.isAllClinic = false;
      }
      this.getUserTypesInCurrentClinic(this.isAllClinic)
    }, (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message })
    });
  }
  getUserTypesInCurrentClinic(clinic) {
    this.userMutiRoleTypeList = [];
    const userTypeIds = [];
    const userTypeResponseLocalMap = [];
    let localUserType;
    let resData = [];
    this.dashboardService.getLoggedUserTypesInCurrentClinic(clinic).subscribe((response) => {
      /* you actual processing function */
      async function itemRunner(obj) {
        // await delay();
        userTypeIds.push(obj.id);
        userTypeResponseLocalMap.push({ id: obj.id, type: obj.type })
        resData.push({ label: obj.type, value: obj.id });
      }
      /* here is how you can wait in loop */
      async function processTasks(array) {
        array.forEach(async (item) => {
          await itemRunner(item);
        })
      }
      processTasks(response.data);

      localUserType = JSON.parse(localStorage.getItem('optplanner_user')).userType;
      this.userMutiRoleTypeModel = localUserType.id;

    })
    setTimeout(() => {
      this.userMutiRoleTypeList = [...resData];
      // console.log(this.userMutiRoleTypeList);
      // console.log(this.userMutiRoleTypeModel);
      // console.log(userTypeIds.includes(this.userMutiRoleTypeModel));
      // console.log(userTypeResponseLocalMap);
      if (!userTypeIds.includes(this.userMutiRoleTypeModel)) {
        this.changeUserRoleDefault(userTypeResponseLocalMap[0])
        this.appExService.getFilteredClinicList.next({ clinicNo: this.selectedClinic })
      } else {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: "User role is changed." });
        this.appExService.getFilteredClinicList.next({ clinicNo: this.selectedClinic })
      }
    }, 1500);



  }

  changeUserRoleDefault(data) {

    let loggedInUserDataGet = JSON.parse(localStorage.getItem('optplanner_user'));

    loggedInUserDataGet.userType = data;
    localStorage.setItem('optplanner_user', JSON.stringify(loggedInUserDataGet));

    window.location.reload();
  }
  changeUserRole(event) {
    let loggedInUserDataGet = JSON.parse(localStorage.getItem('optplanner_user'));

    const data = [];
    this.userMutiRoleTypeList.filter((obj) => {
      if (this.userMutiRoleTypeModel === obj.value) {
        data.push({ id: obj.value, type: obj.label });
      }
    });

    loggedInUserDataGet.userType = data[0];
    localStorage.setItem('optplanner_user', JSON.stringify(loggedInUserDataGet));

    setTimeout(() => {
      this.clinicUpdateInRedis(loggedInUserDataGet.userType)
      // window.location.reload();      
    }, 10);
  }
  clinicUpdateInRedis(data) {
    const payload = {
      id: this.userData.id,
      userTypeId: data.id
    }
    this.dashboardService.changeUserRole(payload).subscribe((res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "User role is changing..." });
      this.getSelectedClinic();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
  }

  roleDropDownClick(e) {
    e.stopPropagation();
  }

  openAddress() {
    const data = localStorage.getItem('optplanner_user') &&
    {
      contact: JSON.parse(localStorage.getItem('optplanner_user')).contact,
    }
    this.addressData = { contact: data.contact, address_type: null, street1: null, street2: null, zip: null, city: null, state: null, country: null };
    this.openAddressPop = true;
  }
  getCountries() {
  }
  getStates(event) {
  }
  addAddressToTable() {
    this.dashboardService.createSingleAddress(this.addressData).subscribe((response) => {
      this.openAddressPop = false;
      this.appService.getUpdatedData(true);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "Address created successfully" });
    }, err => this.messageService.add({ severity: 'error', summary: 'Error', detail: "Something went wrong while creating address" }));
  }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.userData.userType.type === 'provider' || this.userData.userType.type === 'planner') {
          this.ClinicListInRole()
        }
      }
    });
    if (this.router.url === '/setting/all-setting') {
      this.getSelectedClinic()
    }
    this.userData = JSON.parse(localStorage.getItem('optplanner_user'));
    this.availabilityServices.currentUser.next(this.userData);
    if (this.userData.userType.type === 'provider' || this.userData.userType.type === 'planner') {
      this.ClinicListInRole();
    }
    this.getUserTypesInCurrentClinic(this.isAllClinic);
    this.getTimeMessage();
    this.headerClinicListUpdateSub = this.appService.updateHeaderClinicSource$.subscribe((response) => {
      if (response.data) {
        this.userData.assignedClinics = response.data;
        localStorage.setItem('optplanner_user', JSON.stringify(this.userData));
      }
    });
    this.updateHeaderNotificationSub = this.appService.updateHeaderNotificationSource$.subscribe((response) => {
      if (response) {
        this.notificationCountshow = response.data;
        if (response.caller === 'notificationPopup') {
          this.showNotificationFun();
        }
      }
    });
    this.showNotificationFun();

    this.notification = this.appService.showNotification$.subscribe((response: any) => {
      if (response) {
        if (response.data.notificationDTOList) {
          this.notificationCount = 0;
          this.notificationMsg = response.data.notificationDTOList.slice(0, 5)
          this.totalCountNotifications = response.data.notificationDTOList.length
          this.notificationCountshow = response.data.newCount
        }
      }
    })

    this.stopInterval = setInterval(() => {
      this.showNotificationFun();
    }, 60000) // 60000 miliseconds = 60 second

    this.stopRefreshTokenInterval = setInterval(() => {
      this.refreshToken();
    }, 1500000);//1500000 miliseconds = 25 minuts

    this.userType = this.userData.userType.type

    // this.spinnerSub = this.appService.loaderState$.subscribe((state: any) => {
    // this.spinner = state;

    if (this.userType == 'provider' && this.notificationCount == 0) {
      this.notificationCount = this.notificationCount + 1;
      this.showNotificationFun();

    }
    // });
    // this.listClinic = this.userData.assignedClinics;
    this.listClinic = this.userData.assignedClinics.sort((a, b) => a.label.localeCompare(b.label));
    if (this.router.url === ('/dashboard/main-dashboard')) {
      const x = ({ label: 'All', value: -1 })
      this.listClinic2.push(x);
      this.listClinic.length > 1 &&
        this.listClinic.unshift({ label: 'All', value: -1 })
    }
    this.getSelectedClinic();
    this.getUserProfilePic();
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if (data.lang) {
        this.pageStrings = getLanguage(data.lang);
        this.selectedLanguage = data.lang;
      }
    })
    this.headerSub = this.appService.headerSource$.subscribe((page) => {
      console.log('page',page)
      this.pageData = page;
    });
    this.getData = this.appService.getData.subscribe((page) => {
      this.getUserProfilePic();
    });
    this.getRouteInfo = this.router.events.subscribe((params) => {
      this.listClinic = this.userData.assignedClinics;
      if (params["url"] === ('/dashboard') && this.listClinic[0].label !== 'All') {
        this.listClinic.length > 1 &&
          this.listClinic.unshift({ label: 'All', value: -1 })
      }
      else if (this.listClinic[0].label === 'All') {
        if (this.router.url != ('/dashboard/main-dashboard')) {
          this.listClinic.splice(0, 1);
        }
        if (localStorage.assignedClinic) {
          const remD = JSON.parse(localStorage.getItem('optplanner_user'));
          const clinic = parseInt(localStorage.assignedClinic);
          if (this.router.url != ('/dashboard/main-dashboard')) {
            this.profileService.setSelectedClinic({ userID: remD.id, clinicID: clinic }).subscribe((res) => {
              this.selectedClinic = clinic;
            }, (error) => {
              if (error.error.httpStatus === 401 || error.error.httpStatus === 0) {
                this.getRouteInfo.unsubscribe();
                this.router.navigate(["/login"]);
              }
            });
          }
        }
      }
    });
    this.pageStrings = getLanguage(this.selectedLanguage);
  }

  getTimeMessage() {
    var myDate = new Date();
    var hrs = myDate.getHours();

    if (hrs < 12)
      this.greetMessage = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
      this.greetMessage = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
      this.greetMessage = 'Good Evening';
  }

  /**
 * To Refresh Login Token
 */
  refreshToken() {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.authService.refreshLoginTokenAPI().subscribe((response: any) => {
      remD.token = response.data.token;
      localStorage.setItem('optplanner_user', JSON.stringify(remD));

    }, (error) => {
      console.log(error);
    });
  }

  getSelectedClinic() {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.profileService.getSelectedClinic(remD.id).subscribe((res) => {
      if (res) {
        this.selectedClinic = res.data.id;
        localStorage.setItem("assignedClinic", res.data.id);
      }
    });

  }
  showResMessage() {
  }
  updatePageTitle(page) {
    this.title.setTitle(page);
    window.print();
    this.title.setTitle('Opta Planner');
  }

  getUserProfilePic() {
    this.clinicService.getProfile().subscribe((response: any) => {
      if (response.data) {
        const data = response.data;
        this.url = data.documentId || null;
        this.userData.firstName = data.firstName;
        this.userData.lastName = data.lastName;
      }
    });
  }

  getAllNotificationAlert() {
  }

  ngOnDestroy() {
    if (this.headerSub) {
      this.headerSub.unsubscribe();
    }
    if (this.headerClinicListUpdateSub) {
      this.headerClinicListUpdateSub.unsubscribe();
    }
    if (this.updateHeaderNotificationSub) {
      this.updateHeaderNotificationSub.unsubscribe();
    }
    if (this.openMail) {
      this.openMailSubs.unsubscribe();
    }
    if (this.openNotesSubs) {
      this.openNotesSubs.unsubscribe();
    }
    if (this.notification) {
      this.notification.unsubscribe();
    }
    this.getData && this.getData.unsubscribe();
    this.openCalenderSubs && this.openCalenderSubs.unsubscribe();
    this.openPlanSubs && this.openPlanSubs.unsubscribe();
    this.showTaskPoupDataSubs && this.showTaskPoupDataSubs.unsubscribe();
    this.openRolesSubs && this.openRolesSubs.unsubscribe();
    this.langSubs && this.langSubs.unsubscribe();
    this.openQuotationSubs && this.openQuotationSubs.unsubscribe();
    this.openProductSubs && this.openProductSubs.unsubscribe();
    this.openTextMsgSubs && this.openTextMsgSubs.unsubscribe();
    this.getRouteInfo && this.getRouteInfo.unsubscribe();
    clearInterval(this.stopInterval);
    clearInterval(this.stopRefreshTokenInterval);
  }

  callEditPage(data) {
    this.router.navigate(["/user/my-profile"], {
      queryParams: { id: JSON.parse(localStorage.getItem('optplanner_user')).id },
      queryParamsHandling: "merge",
    });
  }
  callUpdatePassword(data) {
    this.router.navigate(["/user/update-password"], {
      queryParams: { id: JSON.parse(localStorage.getItem('optplanner_user')).id },
      queryParamsHandling: "merge",
    });
  }

  /**
   * To Sign out user
   */
  signOut() {
    this.authService.logout().subscribe((res) => {
      localStorage.clear();
      // const remD = localStorage.getItem('rem_d_planner')
      // const auth = localStorage.getItem('auth')
      // localStorage.setItem("optplanner_user", null);
      // localStorage.setItem('rem_d_planner', remD);
      // localStorage.setItem('auth', auth);
      this.appService.updateSidebarView(false);
      this.router.navigate(["/login"]);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "Logged out successfully" });
    }, (error) => {
      this.appService.updateSidebarView(false);
      this.router.navigate(["/login"]);
    });
  }


  resetPassword() {
    const id = localStorage.getItem("optplanner_user") ? JSON.parse(localStorage.getItem("optplanner_user")).id : 0;
    if (id) {
      this.router.navigate([`/reset-password/${id}`]);
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'User id not available, please login again.' });
    }
  }
  /**
   * To open Add new modal based on Selected page
   * @param type Modal Type
   */
  openAddNewModal(type) {
    this.fromEmployeeTab = false;
    switch (type) {
      case "Contacts":
        this.appService.updateModalState(true);
        break;
      case "Subscription Product":
        this.openSubProduct = true;
        break;
      case "Subscription Quotation":
        this.router.navigate(["/subscription/subscription-quotation/mutate-quotation"]);
        break;
      default:
        this.modal.addUser = true;
    }
  }
  /**
   * notification popup closed when click outside.
   * @param $event
   */
  @HostListener("document:click", ["$event"])
  clickedOutside($event) {
    this.showNotification = false;
    this.showHelpData = false;
    this.showAddNotification = false;
    this.showAddActions = false;
    this.showSearchField = false;
  }
  // clickedInside($event: Event) {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  // }
  showSidebar() {
    this.myOutput.emit(true);
    // this.showSidebarElement = !this.showSidebarElement;
  }
  showAbsoluteHeader() {
    this.headerData = !this.headerData;
  }
  getClinicList(data: any) {
    this.clinicService.getClinic(data).subscribe((response: any) => {
      if (response.data) {
        this.listClinic = response.data.map(obj => {
          return ({ label: obj.clinicName, value: obj.id })
        });
      }
    });
  }


  showNotificationWrapper() {
    this.notificationWrapper = !this.notificationWrapper;
  }

  closeNotificationWrapper() {
    this.notificationWrapper = false;

  }



  showNotificationFun() {
    this.clinicService.shownotification().subscribe((response: any) => {
      if (response.data.notificationDTOList) {
        this.notificationCount = 0;
        this.notificationMsg = response.data.notificationDTOList.slice(0, 5)
        this.totalCountNotifications = response.data.notificationDTOList.length
        this.notificationCountshow = response.data.newCount
      }
      this.appService.showNotificationData(response)
    }, (err) => {
      // this.appService.showLoader(true)
      // this.appService.showLoader(true)
    })
  }

  showAllNotifications() {
    this.notificationWrapper = false;
    this.router.navigate(["/user/notifications"], {
      // queryParams: { id: JSON.parse(localStorage.getItem('optplanner_user')).id },
      // queryParamsHandling: "merge",
    });
  }

  notifStatusUpdate(id) {
    this.clinicService.updateNotificationStatus(id).subscribe((response: any) => {
      if (response.success) {
        this.notificationMsg.filter((obj) => obj.id === id)[0].status = "READ";
        this.notificationCountshow = response.data;

      }
    })
  }

  markAllRead() {
    this.clinicService.markAllNotificationStatusRead().subscribe((response: any) => {
      if (response.success) {
        this.showNotificationFun();
        // this.notificationMsg.filter((obj)=> obj.id === id )[0].status = "READ";
        this.notificationCountshow = response.data;

      }
    })
  }

  handel() {
    this.notificationWrapper = false;
  }
  shakeIcon() {
    var icon = document.querySelector('.bell-icon');
    icon.classList.add('shake');
    setTimeout(() => {
      icon.classList.remove('shake')
    }, 700);
  }
  shouldDisableDropdown(): boolean {
    return this.router.url.includes('/clinics/') || this.router.url.includes('/user/all-clinics')
      || (this.userData?.userType.type === 'provider' && this.router.url.includes('/availability/all-availability'))
      || (this.userData?.userType.type === 'provider' && this.router.url.includes('scheduling/all-scheduling'))
      || this.router.url.includes('/patient/patient-details')
      || this.router.url.includes('/job-config/all-job-config')
      || this.router.url.includes('/provider/provider-details')
      || this.router.url.includes('/solver/solver-plan-details')
      || this.router.url.includes('/solver/all-solver/solver-plan-details')
      || this.router.url.includes('/solver/individualgroup-solver/solver-plan-details')
      || this.router.url.includes('/solver/bcba-solver/solver-plan-details')
      || this.router.url.includes('/solver/ot-solver/solver-plan-details')
      || this.router.url.includes('/solver/speech-solver/solver-plan-details')
      || this.router.url.includes('/holidays/all-holidays')
      || this.listClinic.length < 1;
  }
  shouldHideDropdown(): boolean {
    return (this.router.url.includes('/provider/provider-details')
      || this.router.url.includes('/patient/patient-details')
      || this.router.url.includes('/solver/all-solver/solver-plan-details'));
  }
  get truncatedFullName() {
    const maxLength = 15;
    var FullName = this.userData?.firstName + " " + this.userData?.lastName;
    if (FullName.length > maxLength) {
      return FullName.substring(0, maxLength) + '...';
    }
    return FullName;
  }

  ClinicListInRole() {
    this.clinicDropDown = this.userData.userClinicDTOS;
    this.clinicDropDown = this.clinicDropDown.sort((a, b) => a.clinicName.localeCompare(b.clinicName));
    const filteredClinics = this.clinicDropDown.filter(clinic => clinic.userRole === this.userData?.userType.type);
    this.transformedData = filteredClinics.map(clinic => ({
      label: `${clinic.clinicName}`,
      value: clinic.clinicId
    }));
    if (this.router.url === ('/dashboard/main-dashboard')) {
      this.transformedData.unshift({ label: 'All', value: -1 })
    } else {
      const allIndex = this.transformedData.findIndex(option => option.label === 'All');
      if (allIndex !== -1) {
        this.transformedData.splice(allIndex, 1);
      }
    }
  }
}
