import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn:'root'
})
export class AuthenticateGuard implements CanActivate {

    constructor(
        public router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const user = localStorage.getItem('optplanner_user');
    
      if (user && user !== 'null') {
        // Prevent logged-in users from accessing auth pages
        if (['/login', '/signup', '/forgot'].includes(state.url)) {
          this.router.navigate(['/dashboard']);
          return false;
        }
        return true;
      } else {
        // Redirect non-authenticated users to login if not on an auth page
        if (!['/login', '/signup', '/forgot'].includes(state.url)) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      }
    }    

}
